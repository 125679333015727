import "core-js/modules/es6.array.find";
import "core-js/modules/es6.object.freeze";
// import Loading from 'quasar/src/plugins/Loading.js';
import Vue from 'vue';
import Vuex from 'vuex'; // import 'meteor.bundle' // Get meteor bundler in /node_modules

import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
import { Adverts, Measures, Notices, Gebied, Courses } from 'api/collections';
import themes from "./theme-module";
import assessment from "./assessment-module";
import content from "./store-content"; // import createPersistedState from 'vuex-persistedstate'

export var UserData = new Meteor.Collection('userData');
Meteor.subscribe('thisNameDoesNotMatter');
import VuexORM from '@vuex-orm/core';
import Uzer from 'classes/Uzer'; // import { Loading /*, debounce*/ } from 'quasar'

import Loading from 'quasar/src/plugins/Loading.js';;
Vue.use(Vuex);
var database = new VuexORM.Database();
database.register(Uzer);
import * as Sentry from '@sentry/browser';
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

var storeInstance;
/*
const deepFreeze = obj => {
    Object.keys(obj).forEach(prop => {
        if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop])
    })
    return Object.freeze(obj)
}
*/

export default function ()
/* { ssrContext } */
{
  var Store = new Vuex.Store({
    // strict: false,
    // strict: process.env.DEV,
    // plugins: [VuexORM.install(database), createPersistedState()],
    state: {
      user: undefined,
      userid: undefined,
      authenticated: false,
      measures: [],
      courses: [],
      gebied: [],
      measuresMine: [],
      score: -1,
      count: 0,
      ready: false,
      drawnFeature: undefined,
      connected: false,
      showToolbar: true,
      notice: undefined,
      adverts: undefined,
      altmode: false
    },
    modules: {
      themes: themes,
      assessment: assessment,
      content: content
    },
    mutations: {
      // clearAuthData (state) {
      //   // state.idToken = userData.token
      //   state.userId = 'klaas'
      // },
      UPDATE_USER_STATE: function UPDATE_USER_STATE(state, user) {
        if (user) {
          state.authenticated = true;
          state.user = user;
          state.userid = Meteor.userId();
        } else {
          state.user = user;
          state.userid = Meteor.userId();
          state.authenticated = false;
        }
      },
      UPDATE_MEASURES_STATE: function UPDATE_MEASURES_STATE(state, measures) {
        // console.log('UPDATE_MEASURES_STATE')
        // console.log(`count: ${state.count} | measures size:${measures.length} | Data: ${new Date()}`)
        // state.count = state.count + 1

        /* if (state.count === 1) {
            console.log('------1----------')
            // state.measures = Object.freeze(measures)
        } */
        if (state.ready) {
          // console.log('ready !!!....FREEZING MEASURES')
          state.measures = Object.freeze(measures);
        } else {} // console.log('NOT ready....')
        // state.measures = measures

        /* debounce(function () {
         }, 0 /!* ms to wait *!/, true) */

      },
      UPDATE_COURSES_STATE: function UPDATE_COURSES_STATE(state, courses) {
        if (state.ready) {
          state.courses = Object.freeze(courses);
        } else {// console.log('NOT ready....')
        }
      },
      UPDATE_MY_MEASURES_STATE: function UPDATE_MY_MEASURES_STATE(state, measures) {
        state.measuresMine = measures;
      },
      UPDATE_MY_COURSES_STATE: function UPDATE_MY_COURSES_STATE(state, courses) {
        state.coursesMine = courses;
      },
      UPDATE_READY_STATE: function UPDATE_READY_STATE(state, value) {
        state.ready = value;
      },
      UPDATE_DRAWNFEATURE_STATE: function UPDATE_DRAWNFEATURE_STATE(state, value) {
        // console.log(value.payload)
        state.drawnFeature = value.payload;
      },
      UPDATE_CONNECTED_STATE: function UPDATE_CONNECTED_STATE(state, value) {
        state.connected = value;
      },
      UPDATE_SHOWTOOLBAR_STATE: function UPDATE_SHOWTOOLBAR_STATE(state, value) {
        state.showToolbar = value;
      },
      UPDATE_ALT_STATE: function UPDATE_ALT_STATE(state, value) {
        state.altmode = value;
      },
      UPDATE_NOTICES_STATE: function UPDATE_NOTICES_STATE(state, value) {
        state.notice = value;
      },
      UPDATE_GEBIED_STATE: function UPDATE_GEBIED_STATE(state, value) {
        state.gebied = value;
      },
      UPDATE_ADVERTS_STATE: function UPDATE_ADVERTS_STATE(state, value) {
        state.adverts = value;
      }
    },
    // modules: {
    //     measures
    // },
    actions: {
      storeDrawnFeature: function storeDrawnFeature(_ref, payload) {
        var commit = _ref.commit;
        // console.log(' action payloaddd')
        // console.log(payloadd)
        commit('UPDATE_DRAWNFEATURE_STATE', payload);
      },
      setAltState: function setAltState(_ref2, payload) {
        var commit = _ref2.commit;
        commit('UPDATE_ALT_STATE', payload);
      },
      setShowToolbarState: function setShowToolbarState(_ref3, payload) {
        var commit = _ref3.commit;
        commit('UPDATE_SHOWTOOLBAR_STATE', payload);
      },
      initLoginStateTracker: function initLoginStateTracker(_ref4) {
        var commit = _ref4.commit;
        Tracker.autorun(function (c) {
          var user = Meteor.user();
          console.log(user);
          user ? Sentry.setUser({
            id: user._id,
            email: user.profile.email
          }) : Sentry.configureScope(function (scope) {
            return scope.setUser(null);
          });
          commit('UPDATE_USER_STATE', user);
        });
      },
      initConnectedStateTracker: function initConnectedStateTracker(_ref5) {
        var commit = _ref5.commit;
        Tracker.autorun(function (c) {
          var connected = Meteor.status().connected;
          commit('UPDATE_CONNECTED_STATE', connected);
        });
      },
      initAdvertsTracker: function initAdvertsTracker(_ref6) {
        var commit = _ref6.commit;
        Meteor.subscribe('adverts', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Adverts.find({}, {
            sort: {
              'dateCreated': -1
            }
          }).fetch(); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })

          commit('UPDATE_ADVERTS_STATE', item);
        });
      },
      initNoticesTracker: function initNoticesTracker(_ref7) {
        var commit = _ref7.commit;
        Meteor.subscribe('notices', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Notices.findOne({}, {
            sort: {
              'dateCreated': -1
            }
          }); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })

          commit('UPDATE_NOTICES_STATE', item);
        });
      },
      initGebiedTracker: function initGebiedTracker(_ref8) {
        var commit = _ref8.commit;
        Meteor.subscribe('gebied', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Gebied.find({}, {
            sort: {
              'createdAt': -1
            }
          }).fetch(); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })
          // console.log('item', item)

          commit('UPDATE_GEBIED_STATE', item);
        });
      },
      initMeasuresTracker: function initMeasuresTracker(_ref9) {
        var commit = _ref9.commit;
        Meteor.subscribe('measures', {
          onReady: function onReady() {
            // console.log('onReady And the Items actually Arrive', arguments)
            Loading.hide();
            commit('UPDATE_READY_STATE', true);
          }
        });
        Tracker.autorun(function (c) {
          // let items = Measures.find({}, { sort: { dateCreated: -1 }, limit: 5 })
          var items = Measures.find({}, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // item.properties = null

            return item;
          }); // console.log(items)

          commit('UPDATE_MEASURES_STATE', items);
        });
        Tracker.autorun(function (c) {
          var currentUserId = Meteor.userId(); // let items = Measures.find({ 'properties.createdBy': currentUserId }, { sort: { dateCreated: -1 }, limit: 1 })

          var items = Measures.find({
            'createdBy': currentUserId
          }, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // return {
            //     ...item,
            //     foo2: 'bar2'
            // }

            return item;
          });
          commit('UPDATE_MY_MEASURES_STATE', items);
        });
      },
      initCoursesTracker: function initCoursesTracker(_ref10) {
        var commit = _ref10.commit;
        Meteor.subscribe('courses', {
          onReady: function onReady() {
            // console.log('onReady And the Items actually Arrive', arguments)
            Loading.hide();
            commit('UPDATE_READY_STATE', true);
          }
        });
        Tracker.autorun(function (c) {
          // let items = Measures.find({}, { sort: { dateCreated: -1 }, limit: 5 })
          var items = Courses.find({}, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // item.properties = null

            return item;
          }); // console.log(items)

          commit('UPDATE_COURSES_STATE', items);
        });
        Tracker.autorun(function (c) {
          var currentUserId = Meteor.userId(); // let items = Measures.find({ 'properties.createdBy': currentUserId }, { sort: { dateCreated: -1 }, limit: 1 })

          var items = Courses.find({
            'createdBy': currentUserId
          }, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // return {
            //     ...item,
            //     foo2: 'bar2'
            // }

            return item;
          });
          commit('UPDATE_MY_COURSES_STATE', items);
        });
      },
      logout: function logout(_ref11) {
        var commit = _ref11.commit;
        commit('clearAuthData'); // localStorage.removeItem('expirationDate')
        // localStorage.removeItem('token')
        // localStorage.removeItem('userId')
        // router.replace('/signin')
      } // signup ({ commit }, payload) {
      //     commit('changeCCC', payload)
      //     // localStorage.removeItem('expirationDate')
      //     // localStorage.removeItem('token')
      //     // localStorage.removeItem('userId')
      //     // router.replace('/signin')
      // }

    },
    getters: {
      altmode: function altmode(state) {
        return state.altmode;
      },
      // courses: (state, getters) => {
      //     // return state.measures.filter(measure => !measure.like)
      //     return state.courses
      // },
      courses: function courses(state) {
        return function (showAll) {
          return state.authenticated ? showAll ? state.courses : state.coursesMine : state.courses;
        };
      },
      findCourseById: function findCourseById(state) {
        return function (id) {
          return state.courses.find(function (measure) {
            return measure.id === id;
          });
        };
      },
      findProjectById: function findProjectById(state) {
        return function (id) {
          return state.measures.find(function (measure) {
            return measure.id === id;
          });
        };
      },
      measures: function measures(state, getters) {
        // return state.measures.filter(measure => !measure.like)
        return state.measures;
      },
      measuresNotLike: function measuresNotLike(state, getters) {
        return state.measures.filter(function (measure) {
          return !measure.like === true;
        });
      },
      measuresMine: function measuresMine(state, getters) {
        return state.measuresMine;
      },
      projects: function projects(state) {
        return function (showAll) {
          return state.authenticated ? showAll ? state.measures : state.measuresMine : state.measures;
        };
      },
      isAuthenticated: function isAuthenticated(state) {
        return state.authenticated;
      },
      userid: function userid(state) {
        return state.userid;
      },
      user: function user(state) {
        return state.user;
      },
      getCenter: function getCenter(state) {
        // return state.authenticated ? state.user.profile.centerCoordinates : [6.589560, 53.214578]
        return state.authenticated ? state.user.profile.centerCoordinates : [6.589560, 53.214578];
      },
      bannerAd: function bannerAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'BANNER';
          });
          return result;
        }
      },
      timelineAd: function timelineAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIMELINE';
          });
          return result;
        }
      },
      tipsVoedselAd: function tipsVoedselAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIPSVOEDSEL';
          });
          return result;
        }
      },
      tipsNestelAd: function tipsNestelAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIPSNESTEL';
          });
          return result;
        }
      } // getScore (state) {
      //     let score = -1
      //     if (state.authenticated) {
      //         score = state.measures.filter(measure => measure.createdBy === state.user.id)
      //     }
      //     console.log('START')
      //     console.log(state.user)
      //     console.log(state.user.id)
      //     let
      //     console.log(score)
      //     console.log('END')
      //     return score
      // }

    }
  });
  storeInstance = Store;
  return Store;
}
export { storeInstance };