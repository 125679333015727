// This is just an example,
// so you can safely delete all default props below
export default {
  messages: {
    // account_created: 'Please check your email to verify your registration. This sometimes can take up to 10 minutes. Please check the spam folder if the email has not been received.',
    // password_forgot: 'Please check your email to reset your password.',
    // verification_success: 'Your e-mail has been verified. You can now login.',
    // verification_failed: 'Verification has failed.',
    // password_recover_success: 'Your password has been reset. You can now login with the new password.',
    // logout_confirmation: 'Are you sure you want to log out?',
    // confirm: 'Confirm',
    // update_available: 'A new update is available. Click on ok to reload the page.',
    // account_signed_out: 'You have been signed out',
    // account_updated: 'Account succesfully updated'
    account_created: 'Account successfully created',
    password_forgot: 'Please check your email to reset your password.',
    password_forgot_failed: 'Something went wrong, please try again',
    password_reset_succes: 'Your password has been changed. ',
    password_reset_failed: 'Something went wrong, please try again',
    account_signed_out: 'You have been signed out',
    account_updated: 'Account succesfully updated',
    verification_success: 'Your e-mail has been verified. You can now login.',
    verification_failed: 'Verification has failed.',
    password_recover_success: 'Your password has been reset. You can now login with the new password.',
    logout_confirmation: 'Are you sure you want to log out?',
    login_confirmation: 'You have succesfully signed in. Welcome back!',
    login_failed: 'Email and password combination not recognised',
    confirm: 'Confirm',
    update_available: 'A new update is available. Click on ok to reload the page.',
    url_copied_to_clipboard: 'The map url has been copied to clipboard',
    add_area_account_required: 'To add a new project you need to sign in',
    area_deleted: 'Area has been removed',
    area_updated: 'Area has been updated'
  },
  errors: {
    general_error: 'An error occured. Please try again.',
    not_found: 'Sorry, nothing here...',
    network: 'Network error. Please check your internet connection',
    // fields: 'Please correct the input fields',
    required: 'Required field',
    register: {
      password_length: 'The minimum length of the password is {length} characters.',
      password_match: 'Passwords do not match.',
      email_valid: 'A valid email address is required',
      name_length: 'Display name length between {minLength} and {maxLength} characters '
    }
  },
  buttons: {
    submit: 'Submit',
    logout: 'Logout',
    cancel: 'Cancel',
    detail: 'Details',
    login: 'Login',
    register: 'Submit',
    // go_back: 'Go back',
    send: 'Send',
    close: 'Close',
    // previous: 'Previous',
    save: 'Save',
    update: 'Update',
    change: 'Change',
    delete: 'Remove',
    next: 'Next',
    continue: 'Continue',
    back: 'Back',
    goto_authenticate: 'To sign in'
  },
  headers: {
    login: 'Login',
    register: 'Register',
    password_forgot: 'Request a new password',
    password_recover: 'Reset password',
    administrator: 'Administrator',
    navigation: 'Navigation'
  },
  activities: {
    act_cycl: {
      title: 'Cycling',
      desciption: 'TODO'
    },
    act_walk: {
      title: 'Walking or running',
      desciption: 'TODO'
    },
    act_tour: {
      title: 'Touring by car or motor',
      desciption: 'TODO'
    },
    act_natu: {
      title: 'Watching nature',
      desciption: 'TODO'
    },
    act_water: {
      title: 'Water sports',
      desciption: 'TODO'
    },
    act_play: {
      title: 'Playing or sitting',
      desciption: 'TODO'
    },
    act_wintr: {
      title: 'Winter sports',
      desciption: 'TODO'
    },
    act_mount: {
      title: 'Mountain sports',
      desciption: 'TODO'
    }
  },
  labels: {
    account_required: 'Account required',
    read_more: 'read more',
    'area_create_button': 'Save',
    'area_update_button': 'Save',
    'area_created_succes': 'Area is succesfully created',
    'area_updated_succes': 'Area is succesfully updated',
    agree_disclaimer: 'I have understood these above conditions and agree with them',
    my_account: 'My account',
    register: 'Create account',
    logout: 'Sign out',
    login: 'Sign in',
    email: 'Email address',
    name: 'Display name',
    companyName: 'Company name',
    sector: 'Sector',
    address: 'Location',
    address_search: 'Location',
    // zipcodeCompany: 'Zipcode headquarter',
    website: 'Webpage',
    city: 'City',
    // cityCompany: 'City headquarter',
    age: 'Age',
    education: 'Education',
    gender: 'Gender',
    type: 'Type',
    password: 'Password',
    repeat_password: 'Repeat password',
    remember_me: 'Remember me',
    password_forgot: 'Forgot your password?',
    home: 'Home'
  },
  pages: {
    landing: {
      my_portal: 'My Xena'
    },
    welcome: {
      title: 'Welcome to Sustainability Evaluation',
      introduction: 'Introduction',
      // register: 'Register',
      // login: 'Sign in',
      continue_without_account: 'Continue without account',
      login_text: 'If you already have an account, you can sign in'
    },
    introduction: {
      title: 'Introduction',
      text: 'In Greenmapper Friends you can mark attractive, valuable or for you important natural places. These areas have to be places with greenery, water or nature. You can think of a place in a park, at a lake, at sea, in a forest, a meadow, a field of flowers, a spot to watch birds etc. It can be a place within or outside a city or town, small or large. You may visit them often or never! As long as it is a natural place that you find attractive or valuable or is meaningful for you.'
    },
    info_contact: {
      title: 'About',
      contact: 'Contact',
      disclaimer_title: 'Disclaimer',
      implementation: 'Implementation: '
    },
    map: {
      title: 'Map',
      add_btn: 'Draw a new area',
      share_title: 'Share map url',
      share_btn: 'Copy to clipboard',
      hint_on_add: 'Place the dot on the map to start drawing',
      hint_on_end_draw: 'You can still modify the area.' // share_desc: 'Share'

    },
    timeline: {
      title: 'Timeline',
      rating: 'Rating',
      rating_subtext: 'Personal rating of the marked area',
      visit_frequency: 'Visit frequency',
      visit_frequency_subtext: 'How often the area is visited',
      activities: 'Activities',
      activities_subtext: 'Activities reported at marked area',
      add_btn: 'Add an area',
      edit_btn: 'Edit area',
      delete_btn: 'Remove area',
      delete_title: 'Remove area',
      delete_subtitle: 'Are your sure?'
    },
    add_area_info: {
      title: 'Add area',
      add_btn: 'Add an area'
    },
    my_areas: {
      title: 'My areas'
    },
    account: {
      edit_title: 'Edit profile',
      edit_text: 'Edit your current profile information',
      edit_btn: 'Edit profile',
      signout_title: 'Sign out',
      signout_text: 'Sign out of your account',
      signout_btn: 'Sign out'
    },
    create_area: {
      title: 'Create area',
      stepper: {
        description: 'Description',
        rate: 'Rating',
        visit_freq: 'Visiting frequency',
        activities: 'Activities',
        connected: 'Online connected'
      },
      steps: {
        description: {
          nav_title: 'Description',
          title: 'Describe your area',
          task_title: 'Provide a title that represents your area',
          input_title: 'Titel',
          input_title_error: 'Maximum of 50 characters',
          input_description: 'Description',
          task_description: 'State in your own words what makes this particular area attractive, valuable or important to you',
          input_description_error: 'Maximum of 1000 characters'
        },
        rate: {
          nav_title: 'Rating',
          title: 'Rate your area',
          task_title: '(Score: 1 = bad, 6 = average, 10 = perfect)'
        },
        visit_freq: {
          nav_title: 'Visiting frequency',
          title: 'How often do you vistit your area?',
          options: {
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            few_times_a_year: 'Few times a year',
            yearly: 'Yearly',
            rarely: 'Rarely',
            never: 'Never'
          }
        },
        activities: {
          nav_title: 'Activities',
          title: 'What activities do you engage in at this area?'
        },
        priority: {
          nav_title: 'Priority',
          title: 'Should this place be:',
          options: {
            maintained: 'Maintained (= keep it as it is)',
            strengthened: 'Strengthened (= improve its qualities)'
          }
        }
      },
      _edit: {
        title: 'Update area'
      }
    },
    edit_area: {
      title: 'Update area'
    }
  },
  drawer: {
    area_section: 'Discover',
    my_section: 'My overview',
    other_section: 'Other'
  },
  gender: {
    male: 'Male',
    female: 'Female',
    other: 'Other'
  },
  education: {
    1: 'Less than highschool',
    2: 'High school graduate',
    3: 'Some college,no degree',
    4: 'Associate\'s degree',
    5: 'Bachelors degree',
    6: 'Master\'s degree',
    7: 'Professional school degree',
    8: 'Doctorate degree'
  }
};