import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VuePageTransition',
  props: ['name'],
  data: function data() {
    return {
      transition: 'fade',
      mode: 'out-in'
    };
  },
  created: function created() {
    var _this = this;

    this.$router.beforeEach(function (to, from, next) {
      _this.transition = to.meta.transition ? to.meta.transition : _this.$props.name;
      next();
    });
  }
};