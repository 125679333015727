import BWB from 'assets/ESS.json';
window.$currentTask = 'BWB';
window.$taskData = BWB;
window.$selectedMain = [];
window.$mainCount = 0;
window.$selectedSub = [];
window.$sortedMain = [];
window.$sortedSub = [];
window.$sortedSubAll = [];
window.$subCount = 0;
window.$hostname = 'http://localhost:3000';
window.$biodiversity = {};