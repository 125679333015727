var assessment = {
  namespaced: true,
  state: {
    type: undefined,
    id: undefined
  },
  mutations: {
    SET_TYPE: function SET_TYPE(state, newType) {
      console.log('type state:', newType);
      state.type = newType;
    },
    SET_ID: function SET_ID(state, newId) {
      console.log('ID state:', newId);
      state.id = newId;
    }
  },
  actions: {
    setType: function setType(_ref, payload) {
      var commit = _ref.commit;
      console.log('type action ??:', payload);
      commit('SET_TYPE', payload);
    },
    setId: function setId(_ref2, payload) {
      var commit = _ref2.commit;
      console.log('type action ID !!:', payload);
      commit('SET_ID', payload);
    }
  }
}; // const darkTheme = {
//     name: 'Dark',
//     primary: '#222222',
//     secondary: '#111111',
//     accent: '#333333'
// }
//
// const lightTheme = {
//     name: 'Light',
//     primary: '#cce6ff',
//     secondary: '#0052a3',
//     accent: '#f57b00'
// }

export default assessment;