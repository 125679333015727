import isMeteorUser from "../boot/meteor/isMeteorUser.js";
import { Meteor } from 'meteor/meteor';
var routes = [// {
//   path: '/',
//   component: () => import('layouts/TasksLayout'),
//   children: [
//     { name: 'Assessments', path: '', component: () => import('pages/Tasks') }
//   ]
// },
{
  path: '/',
  // parent container, to create simple way to separate parent layout
  component: function component() {
    return import('layouts/DefaultLayout.vue');
  },
  children: [{
    path: '',
    name: 'home',
    beforeEnter: function beforeEnter(to, from, next) {
      /* next({ name: 'Projects' }) */
      isMeteorUser().then(function (response) {
        if (response) {
          if (Meteor.user().profile.teacher) {
            next({
              name: 'Courses'
            });
          } else {
            next({
              name: 'Projects'
            });
          }
        } else {
          next({
            name: 'welcome'
          });
        }
      });
    } // props: true,
    // component: () => import('pages/Projects.vue')

    /* beforeEnter: (to, from, next) => {
        isMeteorUser().then(response => {
            // if true, continue, else redirect to Login page
            // response ? next() : next({ name: 'welcome' })
            response ? next() : next({ name: 'Introduction' })
        })
    } */

  },
  /* {
      path: 'reset-password/:id',
      name: 'resetPassword',
      meta: { showToolbar: false },
      component: () => import('pages/ResetPasswordPage.vue'),
      props: true
  }, */
  {
    path: 'resetpassword/:id',
    name: 'resetPassword',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/ResetPasswordPage.vue');
    },
    props: true
  }, // { path: 'welcome', name: 'welcome', meta: { showToolbar: false }, component: () => import('pages/Welcome.vue') },
  {
    name: 'Project',
    path: 'project/:id',
    component: function component() {
      return import('pages/ProjectPage');
    }
  }, {
    name: 'Summary',
    path: 'summary/:id',
    component: function component() {
      return import('pages/SummaryPage');
    }
  }, {
    name: 'SDG',
    path: 'sdg/:id',
    component: function component() {
      return import('pages/SDGPage');
    }
  }, {
    name: 'ESS',
    path: 'ess/:id',
    component: function component() {
      return import('pages/ESSPage');
    }
  }, {
    name: 'BWB',
    path: 'BWB/:id',
    component: function component() {
      return import('pages/BWBPage');
    }
  }, {
    name: 'Projects',
    path: 'projects',
    component: function component() {
      return import('pages/ProjectsPage');
    }
  }, {
    name: 'Assessments',
    path: 'assessment',
    component: function component() {
      return import('pages/Tasks');
    }
  }, // { name: 'Projects', path: 'projects', component: () => import('pages/Projects') },
  {
    name: 'Prioritize',
    path: 'eisenhower',
    component: function component() {
      return import('pages/Eisenhower');
    }
  }, {
    name: 'Reports',
    path: 'reports',
    component: function component() {
      return import('pages/Reports');
    }
  }, {
    name: 'Courses',
    path: 'courses',
    component: function component() {
      return import('pages/CoursesPage');
    }
  }]
},
/* {
    path: '/login',
    component: () => import('layouts/CenteredLayout.vue'),
    children: [
        {
            path: '',
            name: 'Login',
            component: () => import('pages/LoginPage.vue')
        }
    ]
}, */

/* {
    path: '/pdf',
    component: () => import('layouts/PdfLayout.vue'),
    children: [
        {
            path: '',
            name: 'pdf',
            component: () => import('pages/PdfPage.vue')
        }
    ]
},
{
    path: '/pdf-demo',
    component: () => import('layouts/PdfLayout.vue'),
    children: [
        {
            path: 'sdg/:id',
            name: 'sdg',
            component: () => import('pages/PdfDemoPage.vue')
        }
    ]
}, */
{
  path: '/welcome',
  component: function component() {
    return import('layouts/ColumnLayout.vue');
  },
  children: [{
    path: '',
    name: 'welcome',
    props: true,
    component: function component() {
      return import('pages/Welcome.vue');
    }
    /* beforeEnter: (to, from, next) => {
        isMeteorUser().then(response => {
            // if true, continue, else redirect to Login page
            // response ? next() : next({ name: 'welcome' })
            response ? next({ name: 'Projects' }) : next()
        })
    } */

  }]
}, {
  path: '/SDGA/:id',
  component: function component() {
    return import('layouts/DefaultLayout.vue');
  },
  children: [{
    name: 'SDG',
    path: '',
    component: function component() {
      return import('pages/SDGAPage');
    }
  }]
}, // {
//     path: '/auth',
//     component: () => import('layouts/ColumnLayout.vue'),
//     children: [
//         {
//             path: 'reset-password/:token',
//             name: 'Reset password',
//             component: () => import('pages/ResetPasswordPage.vue')
//             // beforeEnter: (to, from, next) => {
//             //     isMeteorUser().then(response => {
//             //         // if true, continue, else redirect to Login page
//             //         // response ? next() : next({ name: 'welcome' })
//             //         response ? next({ name: 'Projects' }) : next()
//             //     })
//             // }
//         }
//     ]
// },
{
  path: '/assesment',
  // parent container, to create simple way to separate parent layout
  component: function component() {
    return import('layouts/ColumnLayout.vue');
  },
  children: [{
    name: 'Video',
    path: 'video',
    component: function component() {
      return import('pages/VideoPage');
    }
  }, {
    name: 'Categories',
    path: 'category',
    component: function component() {
      return import('pages/CategoryPage');
    }
  }, {
    name: 'Goal sort',
    path: 'goal-sort',
    component: function component() {
      return import('pages/GoalSortPage');
    }
  }, {
    name: 'Sub goal select',
    path: 'sub-goal-select',
    component: function component() {
      return import('pages/SubGoalSelectPage');
    }
  }, {
    name: 'Sub goal sort',
    path: 'sub-goal-sort',
    component: function component() {
      return import('pages/SubGoalSortPage');
    }
  }, {
    name: 'Sub goal hours',
    path: 'sub-goal-hours',
    component: function component() {
      return import('pages/SubGoalHoursPage');
    }
  }, {
    name: 'Sub goal merge sort',
    path: 'sub-goal-merge-sort',
    component: function component() {
      return import('pages/SubGoalSortDualPage');
    }
  },
  /* {
      name: 'Sub goal sort ALL',
      path: 'sub-goal-sort-all',
      component: () => import('pages/SubGoalSortAllPage')
  }, */
  {
    name: 'Biodiversity',
    path: 'biodiversity',
    component: function component() {
      return import('pages/Biodiversity');
    }
  }, {
    name: 'Costs Benefits Revise',
    path: 'cost-benefit',
    component: function component() {
      return import('pages/CostsBenefits');
    }
  }, // { name: "Test sort ALL", path: "test-sort", component: () => import("pages/TestSortPage") },
  // { name: 'Main Category Questions', path: 'question', component: () => import('pages/QuestionPage') },
  // { name: 'Sub Category Questions', path: 'sub-question', component: () => import('pages/SubQuestionPage') },
  {
    name: 'Result',
    path: 'result',
    component: function component() {
      return import('pages/ResultPage');
    }
  } // { name: 'Prioritize', path: 'eisenhower', component: () => import('pages/Eisenhower') },
  // { name: 'Reports', path: 'reports', component: () => import('pages/Reports') },
  // { name: 'Media', path: 'media', component: () => import('pages/MediaPage') }
  ]
}];

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}

export default routes;