import "core-js/modules/es6.function.name";
import Dialog from 'quasar/src/plugins/Dialog.js';;
export default {
  confirmDeleteTask: function confirmDeleteTask(task) {
    return Dialog.create({
      title: 'Delete Assessment',
      message: "Remove task <strong><code>".concat(task.name, "</code></strong> forever?<br/>This action cannot be undone."),
      color: 'negative',
      persistent: true,
      cancel: true,
      html: true
    });
  },
  confirmDeleteProject: function confirmDeleteProject(project) {
    return Dialog.create({
      title: 'Delete Project',
      message: "\n        Remove project <strong><code>".concat(project.properties.name, "</code></strong>\n        <br/>\n        This action cannot be undone\n      "),
      color: 'negative',
      persistent: true,
      cancel: true,
      html: true
    });
  }
};