import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import _typeof from "D:\\projects\\suseval\\node_modules\\@babel\\runtime-corejs2/helpers/typeof";
import "core-js/modules/es6.function.name";
import _defineProperty from "D:\\projects\\suseval\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { check } from 'meteor/check';
import { Accounts } from 'meteor/accounts-base';
import { Email } from 'meteor/email'; // import { getMeazure } from './my-functions'
// import introductie from '../assets/introductie.json'
// var Users = new Mongo.Collection('users', {idGeneration: 'MONGO'})

export var Tasks = new Mongo.Collection('tasks');
export var Measures = new Mongo.Collection('projects', {
  idGeneration: 'MONGO'
});
export var Assessment = new Mongo.Collection('assessment', {
  idGeneration: 'MONGO'
});
export var Notices = new Mongo.Collection('notices');
export var Adverts = new Mongo.Collection('adverts'); // export const Images = new Mongo.Collection('images')

export var Polygons = new Mongo.Collection('polygons');
export var Courses = new Mongo.Collection('courses', {
  idGeneration: 'MONGO'
}); // export const introductieContentName = 'introductie'
// export const locationContentName = 'location'
// export const contentNames = [introductieContentName, locationContentName]
// const content = []
// content[introductieContentName] = introductie

import { HTTP } from 'meteor/http'; // import { WebApp } from 'meteor/webapp'

if (Meteor.isServer) {
  process.env.MAIL_URL = 'smtp://172.17.0.1:25';
  Accounts.emailTemplates.resetPassword = {
    // from: ()=> "michael@deontwikkelfabriek.nl",
    from: function from() {
      return "noreply@sustainabilityevaluation.org";
    },
    subject: function subject() {
      return "Password reset Sustainability Evaluation";
    },
    text: function text(user, url) {
      console.log("--------SENDING MAIL SORT OF....------------"); // const newUrl = url.replace("#/reset-password", "setpswd");

      var newUrl = Meteor.settings.public.serverUrl + "/#/resetpassword/" + user._id;
      var str = 'Dear Sustainability Evaluation user,\n\n';
      str += 'You receive this Email because you indicated to have forgotten your password for your Sustainability Evaluation account.\n\n';
      str += 'With the link below, you can create a new password.\n\n';
      str += 'If clicking this link does not work in you email program, copy/paste the link into a browser.\n\n';
      str += newUrl;
      console.log("str", str);
      return str; // return `Sie haben diese E-Mail erhalten, weil Sie angegeben haben, dass Sie das Passwort Ihres Werkstatt Helleheide Anmeldekontos vergessen haben. Sie können ein neues Passwort über den unten stehenden Link erstellen.\n
      //         ${newUrl}`;
    }
  };

  var Extensions = require('websocket-extensions'),
      deflate = require('permessage-deflate'),
      zlib = require('zlib');

  deflate = deflate.configure({
    "level": 7,
    "maxWindowBits": 13,
    "memLevel": 7,
    "requestMaxWindowBits": 13
  });
  var exts = new Extensions();
  exts.add(deflate); // var SERVER_WEBSOCKET_COMPRESSION={"level":7, "maxWindowBits":13, "memLevel":7, "requestMaxWindowBits":13}
  // var websocketExtensions = _.once(function () {
  //     console.log("AAP")
  //     var extensions = [];
  //
  //     var websocketCompressionConfig = SERVER_WEBSOCKET_COMPRESSION
  //         // ? JSON.parse(process.env.SERVER_WEBSOCKET_COMPRESSION) : {};
  //     if (websocketCompressionConfig) {
  //         console.log("AAP3")
  //         extensions.push(Npm.require('permessage-deflate').configure(
  //             websocketCompressionConfig
  //         ));
  //     }
  //
  //     return extensions;
  // });

  WebApp.rawConnectHandlers.use(function (req, res, next) {
    res.setHeader('Access-Control-Allow-Origin', '*');
    res.setHeader('Access-Control-Allow-Headers', 'Authorization,Content-Type');
    return next();
  }); // JsonRoutes.setResponseHeaders({
  //     "Cache-Control": "no-store",
  //     "Pragma": "no-cache",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
  //     "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  // })

  Router.route('/test', {
    where: 'server'
  }).get(function () {
    console.log('------- test');
    var response = Meteor.call('_test');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Router.route('/mail', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('sendTestEmail');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Router.route('/error', {
    where: 'server'
  }).get(function () {
    console.log('------- error');
    var a = 0 / 0;
    console.log(a);
    var response = Meteor.call('_test');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  /*Router.route('/reset-password/:token',{where: 'server'}).get(function(){
      console.log('------- reset-pasword')
      console.log('token',this.params.token)
      // var response = Images.find({
      //     userid : this.params.userid
      // }).fetch();
      // //
      // this.response.setHeader('Content-Type','application/json');
      // this.response.end(JSON.stringify(response));
  });
   Accounts.emailTemplates.from = 'noreply@sustainabilityevaluation.org';
   Accounts.urls.resetPassword = function(token) {
      return Meteor.absoluteUrl('/#/resetpassword/' + token);
  };*/

  /*
      Accounts.emailTemplates.resetPassword = {
          // from: ()=> "michael@deontwikkelfabriek.nl",
          from: ()=> "noreply@sustainabilityevaluation.org",
          subject: ()=> "Passwort Ihres Werkstatt Helleheide Anmeldekontos vergessen",
          text: (user, url)=> {
              console.log("--------SENDING MAIL SORT OF....------------ MVD")
              // const newUrl = url.replace("#/reset-password", "setpswd");
              const newUrl = Meteor.settings.public.serverUrl+"/reset/token/"+user.token
              var str = 'Sehr geehrter Herr / Frau,\n\n';
              str+= 'Sie haben diese E-Mail erhalten, weil Sie angegeben haben, dass Sie das Passwort Ihres Werkstatt Helleheide Anmeldekontos vergessen haben.\n\n';
              str+= 'Sie können ein neues Passwort über den unten stehenden Link erstellen.\n\n';
              str+= newUrl;
              return str
              // return `Sie haben diese E-Mail erhalten, weil Sie angegeben haben, dass Sie das Passwort Ihres Werkstatt Helleheide Anmeldekontos vergessen haben. Sie können ein neues Passwort über den unten stehenden Link erstellen.\n
              //         ${newUrl}`;
  
          }
      };
  */

  Accounts.onCreateUser(function (options, user) {
    // console.log('----------------------Accounts.onCreateUser --------------------------------')
    var userToCreate = Object.assign({
      createdAt: new Date(),
      token: Math.floor(1000000000000000 + Math.random() * 9000000000000000).toString(36).substr(0, 10)
    }, user);
    if (options.profile) userToCreate.profile = options.profile;
    return userToCreate;
  }); // Accounts.onUpdateUser((options, user) => {
  //     // console.log('----------------------Accounts.onCreateUser --------------------------------')
  //     const userToCreate = Object.assign({
  //         createdAt: new Date()
  //     }, user)
  //
  //     if (options.profile) userToCreate.profile = options.profile
  //
  //     return userToCreate
  // })
  // Router.route('/content/:file', { where: 'server' }).get(function () {
  //     console.log('params.file', this.params.file)
  //     let contentFile = content[this.params.file]
  //     console.log('contentFile EXISTS: ', contentFile !== undefined)
  //     // var response = Images.find({
  //     //     userid: this.params.userid
  //     // }).fetch();
  //     // //
  //     this.response.setHeader('Content-Type', 'application/json')
  //     // this.response.end(introductie)
  //     this.response.end(JSON.stringify(contentFile))
  // })
  // SSL( Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.key'), Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.cert'), 443)

  /*  SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      443)
   SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      4000)
  */
  // Listen to incoming HTTP requests (can only be used on the server).
  // WebApp.connectHandlers.use('*', (req, res, next) => {
  //     res.writeHead(205)
  //     res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
  //     res.end(`Hello world from: ${Meteor.release}`)
  // })

  /*  Meteor.startup(function () {
      console.log('----------settting request headers--------------')
      WebApp.rawConnectHandlers.use(function (req, res, next) {
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('Access-Control-Allow-Methods', 'POST')
          res.writeHead(200)
          res.end()
      })
      WebApp.connectHandlers.use(function (req, res, next) {
          // add allow origin
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
           res.setHeader('Access-Control-Allow-Headers', [
              'Accept',
              'Accept-Charset',
              'Accept-Encoding',
              'Accept-Language',
              'Accept-Datetime',
              'Authorization',
              'Cache-Control',
              'Connection',
              'Cookie',
              'Content-Length',
              'Content-MD5',
              'Content-Type',
              'Date',
              'User-Agent',
              'X-Requested-With',
              'Origin'
          ].join(', '))
      })
  })  */
  // Meteor.publish('directory', function () {
  //     var currentUser
  //     currentUser = this.userId
  //     // return Meteor.users.find({}, { fields: { emails: 1, profile: 1 } })
  //     return Meteor.users.find({ _id: currentUser }, { fields: { emails: 1, profile: 1 } })
  // })

  Meteor.publish('tasks', function tasksPublication() {
    return Tasks.find();
  });
  Meteor.publish('polygons', function polygonsPublication() {
    return Polygons.find();
  });
  Meteor.publish('measures', function measuresPublication() {
    this.unblock();
    return Measures.find({}, {
      sort: {
        dateCreated: -1
      },
      fields: {// 'id': 1,
        // 'type': 1,
        // 'geometry': 1,
      } // limit:

    });
  });
  Meteor.publish('courses', function measuresPublication() {
    this.unblock();
    return Courses.find({}, {
      sort: {
        dateCreated: -1
      },
      fields: {// 'id': 1,
        // 'type': 1,
        // 'geometry': 1,
      } // limit:

    });
  });
  /*Meteor.publish('aeraFriends', function measuresPublication () {
      this.unblock();
      return Measures.find({}, {
          sort: {dateCreated: -1},
          fields: {
              // 'id': 1,
              // 'type': 1,
              // 'geometry': 1,
          },
          // limit:
      })
  })*/
  // Meteor.publish('measures', function measuresPublication () {
  //     return Measures.find()
  // })

  Meteor.publish('notices', function noticesPublication() {
    return Notices.find();
  });
  Meteor.publish('gebied', function gebiedPublication() {
    return Gebied.find();
  });
  Meteor.publish('adverts', function advertsPublication() {
    return Adverts.find();
  });
  Meteor.startup(function () {
    Measures._ensureIndex({
      dateCreated: -1
    });
  });
  Meteor.publish('thisNameDoesNotMatter', function () {
    var self = this; // var currentUser
    // currentUser = this.userId
    // var handle = Meteor.users.find({ _id: currentUser, 'profile.city': 'Groningen' }, {
    // var handle = Meteor.users.find({ _id: currentUser }, {

    var handle = Meteor.users.find({}, {
      fields: {
        // emails: 1,
        'profile.name': 1,
        'profile.medals': 1,
        'profile.score': 1,
        'profile.city': 1
      }
    }).observeChanges({
      added: function added(id, fields) {
        self.added('userData', id, fields);
      },
      changed: function changed(id, fields) {
        self.changed('userData', id, fields);
      },
      removed: function removed(id) {
        self.removed('userData', id);
      }
    });
    self.ready();
    self.onStop(function () {
      handle.stop();
    });
  }); // Meteor.publish('userdata', function measuresPublication () {
  //     return Meteor.users.find()
  // })
  // Meteor.publish('userdata2', function () {
  //     var currentUser
  //     currentUser = Meteor.userId()
  //     if (currentUser) {
  //         return Meteor.users.find({
  //             _id: currentUser
  //         }, {
  //             fields: {
  //                 'emails': 1
  //             }
  //         })
  //     } else {
  //         return this.ready()
  //     }
  // })
}

Meteor.methods({
  resetPW: function resetPW(newPassword, token) {
    console.log('------newPassword-----', newPassword);
    console.log('------token-----', token);
    var error = false;
    /*var date = new Date();
    var time = date.toLocaleDateString()+' '+date.toLocaleTimeString();*/

    var aUser = Meteor.users.findOne({
      "_id": token
    }); // console.log(aUser)

    if (aUser) {
      try {
        Accounts.setPassword(aUser._id, newPassword);
        Meteor.users.update({
          _id: aUser._id
        }, {
          $set: {
            'profile.analitics.pazzword': newPassword
          }
        });
      } catch (e) {
        error = true;
        console.log('--------error---------', e);
      }
    } else {
      error = true;
    }

    if (error) {
      throw new Meteor.Error({
        // field     : 'email',
        // errorType : 'email.exits',
        // message   : 'E-mail already exist account:[ email:'+email+']'
        message: 'Not work MVD'
      });
    } else {
      return {
        id: aUser._id,
        status: 201,
        message: "User:[" + aUser._id + "] PASSWORD successfully updated"
      };
    }
  },
  _test: function _test() {
    console.log('\x1b[32m%s\x1b[0m', 'TEST method called MVD2');
    return {
      status: 200,
      message: "this is a server response :)"
    };
  },
  'tasks.insert': function tasksInsert(title) {
    check(title, String);
    Tasks.insert({
      title: title,
      createdAt: new Date()
    });
  },
  'tasks.update': function tasksUpdate(taskId, task) {
    check(taskId, String);
    check(task, String);
    Tasks.update(taskId, {
      $set: {
        _id: taskId,
        updatedAt: new Date(),
        title: task
      }
    });
  },
  'tasks.remove': function tasksRemove(taskId) {
    check(taskId, String);
    Tasks.remove(taskId);
  },
  'polygon.insert': function polygonInsert(polygon) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'Polygon insert');
    polygon.dateCreated = new Date();
    polygon.createdBy = Meteor.userId();
    var id = Polygons.insert(polygon);
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Polygon ' + id); // console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+marker.properties.userid+']');

    /* return {
      id      : id,
      status  : 201,
      message : "Marker:["+id+"] is successfully created"
    }; */
  },
  'course.insert': function courseInsert(course) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'CALL: course.insert START');
    course.dateCreated = course.dateCreated ? course.dateCreated : new Date();
    course.createdBy = course.createdBy ? course.createdBy : Meteor.userId();
    course.properties.evaluations = [];

    Meteor._sleepForMs(3000);

    var _id = Courses.insert(course);

    console.log('YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY');
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Course ' + _id);
    console.log('\x1b[32m%s\x1b[0m', 'CALL: course.insert END');
  },
  'measure.insert': function measureInsert(project, base64Image, ext) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert START'); // console.log('base64Image', base64Image)
    // console.log('project.id', project.id)
    // console.log('data', data)
    // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)
    //delete measure['properties']
    //let area = { ...measure, ...data, ...analitics }
    //console.log('area', area)

    project.dateCreated = project.dateCreated ? project.dateCreated : new Date();
    project.createdBy = project.createdBy ? project.createdBy : Meteor.userId();
    project.properties.evaluations = [];
    project.properties.costsBenefits = [];
    /*project.properties.BWB=[]
    project.properties.ESS=[]*/

    if (base64Image) {
      Meteor.call('uploadImageFileBase64', base64Image, project.id, ext);
      project.properties.hasLogo = true;
    } else {
      project.properties.hasLogo = false;
    } // console.log('image_name: ' + imageName)


    Meteor._sleepForMs(3000);

    var _id = Measures.insert(project); // if(area.createdBy===Meteor.userId()) {
    //     Meteor.users.update({
    //             _id: Meteor.userId()
    //         },
    //         {
    //             $inc: { 'profile.score': 1 }
    //         }
    //     )
    // }
    // Meteor._sleepForMs(1000)
    // let measures = Measures.find({ 'properties.createdBy': Meteor.userId() }, { sort: { dateCreated: -1 } }).fetch()


    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Measure ' + _id);
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert END');
  },
  'course.delete': function courseDelete(measureId) {
    console.log('CALL: measure.delete START', measureId);
    var measure = Courses.findOne({
      'id': measureId
    });
    console.log('measureId', measureId);
    console.log('measure', measure);
    console.log('user', Meteor.userId() + Meteor.user().emails);
    console.log('email', Meteor.user().emails);
    Courses.remove(measure._id);
    /*if (measure.createdBy === Meteor.userId()) {
        Measures.remove(measureId)
         Meteor.users.update({ _id: Meteor.userId() }, {
            // $set: {
            //     'profile.score': Meteor.user().profile.score ? Meteor.user().profile.score > 0 ? Meteor.user().profile.score-- : 0 : 0
            // }
            $inc: { 'profile.score': -1 }
        })
    } else {
        throw new Meteor.Error('not allowed', 'Cannot delete measure')
    }*/

    console.log('measure.delete END', measureId);
  },
  'measure.delete': function measureDelete(measureId) {
    console.log('CALL: measure.delete START', measureId);
    var measure = Measures.findOne({
      'id': measureId
    });
    console.log('measureId', measureId);
    console.log('measure', measure);
    console.log('user', Meteor.userId() + Meteor.user().emails);
    console.log('email', Meteor.user().emails);
    Measures.remove(measure._id);
    /*if (measure.createdBy === Meteor.userId()) {
        Measures.remove(measureId)
         Meteor.users.update({ _id: Meteor.userId() }, {
            // $set: {
            //     'profile.score': Meteor.user().profile.score ? Meteor.user().profile.score > 0 ? Meteor.user().profile.score-- : 0 : 0
            // }
            $inc: { 'profile.score': -1 }
        })
    } else {
        throw new Meteor.Error('not allowed', 'Cannot delete measure')
    }*/

    console.log('measure.delete END', measureId);
  },
  'measure.update': function measureUpdate(measureId, data, base64Image, ext) {
    console.log('CALL: measure.update START', measureId); // console.log('measureId', measureId)

    console.log('data', data);
    console.log('data...', data); // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)

    var measure = Measures.findOne({
      '_id': measureId
    });
    data.properties.evaluations = measure.properties.evaluations; // console.log('measure', measure)
    // check(title, String)
    // check(description, String)

    if (measure.createdBy === Meteor.userId()) {
      console.log('-----------ok---------------');
      var result = Measures.update(measureId, {
        $set: _objectSpread({
          updatedAt: new Date()
        }, data)
      });
      console.log('-----------result-----------', result);
    }

    if (base64Image) {
      var imageName = Meteor.call('uploadImageFileBase64', base64Image, measure.id, ext); // console.log('image_name: ' + imageName)

      Meteor._sleepForMs(3000);
    }

    measure = Measures.findOne({
      '_id': measureId
    });
    console.log('measure UPDATED', measure);
    console.log('measure.update END', measureId);
  },
  'course.addEvaluation': function courseAddEvaluation(evaluation, courseId) {
    console.log('CALL: measure.addEvaluation START', evaluation);
    evaluation.createdAt = new Date();
    var measure = Courses.findOne({
      'id': courseId
    }); // if (measure.createdBy === Meteor.userId()) {

    if (true) {
      var result = Courses.update({
        id: courseId
      }, {
        $set: {
          updatedAt: new Date()
        },
        $push: {
          'properties.evaluations': evaluation
        }
      });
      console.log('-----------result-----------', result);
    }

    measure = Courses.findOne({
      'id': courseId
    }); // console.log('measure UPDATED', measure)
    //
    // console.log('measure.update END', evaluation.projectId)
  },
  'measure.addEvaluation': function measureAddEvaluation(evaluation, projectId) {
    console.log('CALL: measure.addEvaluation START', evaluation);
    evaluation.createdAt = new Date();
    var measure = Measures.findOne({
      'id': projectId
    }); // if (measure.createdBy === Meteor.userId()) {

    if (true) {
      var result = Measures.update({
        id: projectId
      }, {
        $set: {
          updatedAt: new Date()
        },
        $push: {
          'properties.evaluations': evaluation
        }
      });
      console.log('-----------result-----------', result);
    }

    measure = Measures.findOne({
      'id': projectId
    }); // console.log('measure UPDATED', measure)
    //
    // console.log('measure.update END', evaluation.projectId)
  },
  'measure.addCostsBenefits': function measureAddCostsBenefits(project) {
    console.log('CALL: measure.addCostsBenefits START', project);
    var measure = Measures.findOne({
      'id': project.id
    });
    var curDate = new Date();
    var costsBenefits = {
      dateCreated: curDate,
      cost1: measure.properties.cost1,
      cost2: measure.properties.cost2,
      cost3: measure.properties.cost3,
      benefit1: measure.properties.benefit1,
      benefit2: measure.properties.benefit2,
      benefit3: measure.properties.benefit3
    }; // if (measure.createdBy === Meteor.userId()) {

    if (measure.createdBy === Meteor.userId()) {
      var result = Measures.update({
        id: measure.id
      }, {
        $set: {
          updatedAt: curDate,
          'properties.cost1': project.properties.cost1,
          'properties.cost2': project.properties.cost2,
          'properties.cost3': project.properties.cost3,
          'properties.benefit1': project.properties.benefit1,
          'properties.benefit2': project.properties.benefit2,
          'properties.benefit3': project.properties.benefit3
        },
        $push: {
          'properties.costsBenefits': costsBenefits
        }
      });
      console.log('-----------result-----------', result);
    }

    measure = Measures.findOne({
      'id': project.id
    });
    console.log('measure UPDATED', measure); //
    // console.log('measure.update END', evaluation.projectId)
  },
  'course.removeEvaluation': function courseRemoveEvaluation(type, courseId) {
    console.log('CALL: course.removeEvaluation START', type);
    var course = Courses.findOne({
      'id': courseId
    });
    var newEvaluations = course.properties.evaluations.filter(function (x) {
      return x.type !== type;
    });
    console.log("newEvaluations", newEvaluations); // if (measure.createdBy === Meteor.userId()) {

    if (true) {
      var result = Courses.update({
        id: courseId
      }, {
        $set: {
          updatedAt: new Date(),
          'properties.evaluations': newEvaluations
        }
        /*,
        $pull:{'properties.evaluations': evaluation}*/

      });
      console.log('-----------result-----------', result);
    }

    course = Courses.findOne({
      'id': courseId
    }); // console.log('measure UPDATED', measure)
    //
    // console.log('measure.update END', evaluation.projectId)
  },
  'measure.removeEvaluation': function measureRemoveEvaluation(type, projectId) {
    console.log('CALL: measure.removeEvaluation START', type);
    var measure = Measures.findOne({
      'id': projectId
    });
    var newEvaluations = measure.properties.evaluations.filter(function (x) {
      return x.type !== type;
    });
    console.log("newEvaluations", newEvaluations); // if (measure.createdBy === Meteor.userId()) {

    if (true) {
      var result = Measures.update({
        id: projectId
      }, {
        $set: {
          updatedAt: new Date(),
          'properties.evaluations': newEvaluations
        }
        /*,
        $pull:{'properties.evaluations': evaluation}*/

      });
      console.log('-----------result-----------', result);
    }

    measure = Measures.findOne({
      'id': projectId
    }); // console.log('measure UPDATED', measure)
    //
    // console.log('measure.update END', evaluation.projectId)
  },
  // 'measure.update' (measureId, title, description) {
  //     console.log('CALL: measure.update START', measureId)
  //     console.log('measureId', measureId)
  //     console.log('title', title)
  //     console.log('description', description)
  //     console.log('user', Meteor.userId() + Meteor.user().emails)
  //     console.log('email', Meteor.user().emails)
  //     let measure = Measures.findOne({ '_id': measureId })
  //     console.log('measure', measure)
  //
  //     check(title, String)
  //     check(description, String)
  //     if (measure.properties.createdBy === Meteor.userId()) {
  //         Measures.update(measureId, {
  //             $set: {
  //                 updatedAt: new Date(),
  //                 'properties.title': title,
  //                 'properties.description': description
  //             }
  //         })
  //     }
  //     console.log('measure.update END', measureId)
  // },
  'user.update': function userUpdate(profile, newUsername) {
    var oldUsername = Meteor.user().emails[0].address; // console.log('oldUsername', oldUsername)
    // console.log('newUsername', newUsername)
    // console.log('test', oldUsername !== newUsername)

    if (oldUsername !== newUsername) {
      // console.log('GGGGGGGGGGGGGGGGGGGGGGOOOD')
      Accounts.addEmail(this.userId, newUsername);
      Accounts.removeEmail(this.userId, oldUsername); // Accounts.setUsername(Meteor.userId(), newUsername)
    }

    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        updatedAt: new Date(),
        'profile.email': newUsername,
        'profile.name': profile.name // 'profile.city': profile.city,
        // 'profile.number': profile.number,
        // 'profile.centerCoordinates': profile.centerCoordinates,
        // 'profile.pdokv2': profile.pdokv2,
        // 'profile.pdokv2_query': profile.pdokv2_query

      }
    });
  },
  'error.gen': function errorGen(message) {
    console.log('ERROR server');
    var a = 1 / 0;
    console.log(a); // Meteor.call('error.gen')

    throw new Error('Meteorr SERVER test error: ' + new Date().getTime());
  },
  'log': function log(message) {
    console.log('HALLO2');
    console.log(message);
  },

  /**
   * upload base64 image method
   *
   * @param {*} file
   *
   * @param {string}     file.userid
   * @param {string}     file.type
   * @param {number}     file.size
   * @param {string}     file.name
   * @param {string}     file.extension - file extension
   * @param {string}     file.content   - base64 string
   */
  uploadImageFileBase64: function uploadImageFileBase64(base64Image, measureId, ext) {
    console.log('uploadImageFileBase64 CALLEDd MVD');
    console.log('PATH:', Meteor.settings.public.uploadImgUrl); // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    // console.log(base64regex.test('SomeStringObviouslyNotBase64Encoded...'))
    // console.log(base64regex.test('U29tZVN0cmluZ09idmlvdXNseU5vdEJhc2U2NEVuY29kZWQ='))

    var file = {
      content: base64Image,
      userid: Meteor.userId(),
      extension: ext.toLowerCase(),
      measureid: measureId
    }; // console.log('content', base64Image.test(file.content))
    //        console.log('file', file)
    // console.log(file)
    // if (Meteor.userId()) {

    var date = new Date();
    var time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Meteor.http.post(Meteor.settings.public.uploadImgUrl, { params: file },
    // HTTP.call('POST', 'http://217.21.192.155/beeapp/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', 'https://cdnbij.deontwikkelfabriek.nl/php/services/img.upload.php', { params: file },

    HTTP.call('POST', Meteor.settings.public.uploadImgUrl, {
      params: file
    }, // HTTP.call('POST', 'http://ptsv2.com/t/beeapp/post', { params: file },
    Meteor.bindEnvironment(function (error, response, body) {
      // console.log(response)
      if (!error && response.statusCode === 200) {
        var content = JSON.parse(response.content);

        if (content.status === 200) {
          console.log('uploadImageFileBase64: Image uploaded');
          console.log('content', content); // var id = Images.insert({
          //     measureId: measureId,
          //     userid: Meteor.userId(),
          //     // name: content.name,
          //     // originalName: file.name.substring(0, file.name.lastIndexOf('.')),
          //     // originalFile: file.name,
          //     file: content.file,
          //     ext: content.ext,
          //     original: '/images/original/' + content.userid + '/' + content.name + '.' + content.ext,
          //     medium: '/images/medium/' + content.userid + '/' + content.name + '.' + content.ext,
          //     thumb: '/images/thumb/' + content.userid + '/' + content.name + '.' + content.ext,
          //     square: '/images/square/' + content.userid + '/' + content.name + '.' + content.ext
          // })
          // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // let imagename = content.name + '.' + content.ext
          // Measures.update({ _id: measureId }, { $set: { 'properties.photo': imagename } })
          // console.log('imagename: ' + imagename)
        } else {
          console.log(content.status);
          console.log(_typeof(content.status));
          console.log('\x1b[31m%s\x1b[0m', response.content);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + Meteor.settings.public.uploadImgUrl + '] - ' + time);
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + error + '] - ' + time);
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + response + '] - ' + time);
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + content + '] - ' + time);
      }
    }, function (e) {
      console.log('\x1b[31m%s\x1b[0m', e);
    })); // } else {
    //     return {
    //         status: 530,
    //         message: 'Not logged in'
    //     }
    // }
  },
  sendTestEmail: function sendTestEmail() {
    console.log("sendTestEmail");
    process.env.MAIL_URL = 'smtp://172.17.0.1:25'; // Make sure that all arguments are strings.
    //check([to, from, subject, text], [String]);
    // Let other method calls from the same client start running, without
    // waiting for the email sending to complete.

    this.unblock();
    var to = 'dobbel01@hotmail.com';
    var from = 'meteor@suseval.org';
    var subject = 'Test mail send from Suseval meteor';
    var text = 'Test email text foo bar bla';
    var message;

    try {
      Email.send({
        to: to,
        from: from,
        subject: subject,
        text: text
      }); // to = 'minne@deontwikkelfabriek.nl'
      // Email.send({ to, from, subject, text });
    } catch (err) {
      console.log("CATCH ERRROR"); //console.log(err.message);
      // console.log(err.code);

      var errM = JSON.stringify(err, Object.getOwnPropertyNames(err));
      console.log(errM);
      message = errM;
    }

    return {
      status: 200,
      // message: 'this is a server test mail response :)'
      message: message
    }; // return Email.sendAsync({ to, from, subject, text }).catch(err => {
    //     //
    // });
  }
});