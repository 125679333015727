//
//
//
//
//
//
//
//
import VuePageTransition from 'components/VuePageTransition';
export default {
  name: 'App',
  components: {
    VuePageTransition: VuePageTransition
  },
  created: function created() {// let a = 10 / 0
    // console.log('ERROR 123')
    // console.log('ERROR', a)
    // let b = { message: 'Parameter is not a number!' }

    /* return Promise.all([
        this.$store.dispatch('tasks/fetchTasks'),
        this.$store.dispatch('projects/fetchProjects')
    ]) */
  },
  beforeDestroy: function beforeDestroy() {
    this.$o.tearDown();
  },
  methods: {
    onResume: function onResume() {
      // console.log('App.vue: cordova:onResume ')
      this.$meteor.reconnect();
      this.$store.dispatch('content/loadContent');
    }
  }
};