/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import lang from 'quasar/lang/en-us';
import iconSet from 'quasar/icon-set/material-icons';
import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QItem from 'quasar/src/components/item/QItem.js';import QItemSection from 'quasar/src/components/item/QItemSection.js';import QSlideItem from 'quasar/src/components/slide-item/QSlideItem.js';import QCard from 'quasar/src/components/card/QCard.js';import QLayout from 'quasar/src/components/layout/QLayout.js';import QHeader from 'quasar/src/components/header/QHeader.js';import QFooter from 'quasar/src/components/footer/QFooter.js';import QDrawer from 'quasar/src/components/drawer/QDrawer.js';import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';import QPage from 'quasar/src/components/page/QPage.js';import QChip from 'quasar/src/components/chip/QChip.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCardActions from 'quasar/src/components/card/QCardActions.js';import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';import Ripple from 'quasar/src/directives/Ripple.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import BottomSheet from 'quasar/src/plugins/BottomSheet.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';;
Vue.use(Quasar, {
  config: {},
  lang: lang,
  iconSet: iconSet,
  components: {
    QBtn: QBtn,
    QItem: QItem,
    QItemSection: QItemSection,
    QSlideItem: QSlideItem,
    QCard: QCard,
    QLayout: QLayout,
    QHeader: QHeader,
    QFooter: QFooter,
    QDrawer: QDrawer,
    QToolbar: QToolbar,
    QToolbarTitle: QToolbarTitle,
    QPageContainer: QPageContainer,
    QSpinnerDots: QSpinnerDots,
    QPage: QPage,
    QChip: QChip,
    QCardSection: QCardSection,
    QCardActions: QCardActions,
    QItemLabel: QItemLabel,
    QLinearProgress: QLinearProgress,
    QSlideTransition: QSlideTransition
  },
  directives: {
    Ripple: Ripple,
    ClosePopup: ClosePopup
  },
  plugins: {
    Loading: Loading,
    Dialog: Dialog,
    Notify: Notify,
    BottomSheet: BottomSheet,
    LocalStorage: LocalStorage,
    AppFullscreen: AppFullscreen
  }
});